import React from "react"
import { Link } from "gatsby"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import { Main, MainContainer } from "../components/content/Privacy/styled"

const Privacy = () => {
  return (
    <Layout>
      <Helmet
        name="Privacy Policy"
        description="View our complete privacy policy here on how Mechanical Air Conditioning collects and utilizes user data."
        slug="privacy"
      />
      <Header />
      <PageHdr title="Privacy Policy" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <p>
                Mechanical Air Conditioning is sensitive to the private nature
                of information you provide to us over the Internet. Our Privacy
                Policy is designed to protect your personal information while at
                the same time giving you the opportunity to obtain interesting
                and useful information, products and services.
              </p>

              <p>
                Personal information may include your name, phone number,
                address and email address along with other information by which
                you can be personally identified.
              </p>

              <p>
                We also collect usage information from you when you visit us.
                web site usage information is not identifiable information. It
                describes how our web site is used and navigated, including the
                number and frequency of visitors to each web page and the length
                of their stay. web site usage information also includes the
                domain names of browsers that visit our web site, time of day
                the web site was visited and other non–personally identifiable
                information.
              </p>

              <p>
                Please <Link to="/contact">contact us</Link> if you have any
                further questions about our privacy policy or to stop receiving
                future emails, phone calls, or other direct communications from
                Mechanical Air Conditioning. This web site uses the Google
                AdWords remarketing service to advertise on third party websites
                (including Google) to previous visitors to our site. It could
                mean that we advertise to previous visitors who haven’t
                completed a task on our site, for example using the contact form
                to make an inquiry. This could be in the form of an
                advertisement on the Google search results page, or a site in
                the Google Display Network. Third–party vendors, including
                Google, use cookies to serve ads based on someone’s past visits
                to the Mechanical Air Conditioning website. Any data collected
                will be used in accordance with our privacy policy and Google’s
                privacy policy.
              </p>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Privacy
